<template>
  <div class="container">
    <edit-header
      entity="shoppingList"
      :error.sync="error"
      :invalid.sync="invalid"
      :submitted.sync="submitted"
      :isNew="isNew">
    </edit-header>
    <form
      ref="form"
      class="validation"
      novalidate
      @submit.prevent="submit">
      <div class="row row-cards row-deck">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Recipe Summary</h3>
              <div class="card-options">
                <button
                  v-if="can(uiPermissions.INTERNAL_PURCHASE_RECIPES_EXPORT)"
                  :disabled="!selectedRecipes.length"
                  class="btn btn-primary mr-4"
                  @click.prevent="exportRecipes">
                  Export
                </button>
                <button
                  v-if="can(uiPermissions.INTERNAL_PURCHASE_RECIPES_EXPORT)"
                  :disabled="!selectedRecipes.length"
                  class="btn btn-primary mr-4"
                  @click.prevent="exportRecipesIngredientsPortioning">
                  Portioning Export
                </button>
                <button
                  v-if="can(uiPermissions.INTERNAL_PURCHASE_RECIPE_CARDS_DOWNLOAD)"
                  type="button"
                  :disabled="isDownloadLinkDisabled"
                  :class="{ 'is-disabled': isDownloadLinkDisabled }"
                  class="btn btn-primary"
                  @click="downloadPDF">
                  Download PDF
                </button>
              </div>
            </div>
            <dimmer :active="loadingRecipes">
              <div v-if="item.notes" class="card-body">
                Notes: {{item.notes}}
              </div>
              <div style="max-height: 25rem; overflow-y: auto;">
                <table class="table table-vcenter card-table sticky-table">
                  <thead>
                    <tr class="thead-light">
                      <th v-if="can([uiPermissions.INTERNAL_PURCHASE_RECIPES_EXPORT, uiPermissions.INTERNAL_PURCHASE_RECIPE_CARDS_DOWNLOAD])" class="text-center">
                        <label class="custom-control custom-checkbox d-flex align-items-center justify-content-center">
                          <input
                            v-model="selectedAllRecipes"
                            type="checkbox"
                            class="custom-control-input">
                          <span class="custom-control-label">&nbsp;</span>
                        </label>
                      </th>
                      <th>Recipe</th>
                      <th>Available for print</th>
                      <th>Requested By</th>
                      <th>2 people</th>
                      <th>3 people</th>
                      <th>4 people</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in recipes" :key="item.id">
                      <td v-if="can([uiPermissions.INTERNAL_PURCHASE_RECIPES_EXPORT, uiPermissions.INTERNAL_PURCHASE_RECIPE_CARDS_DOWNLOAD])" class="text-center">
                        <label class="custom-control custom-checkbox mb-0">
                          <input
                            v-model="selectedRecipes"
                            :value="item"
                            type="checkbox"
                            class="custom-control-input">
                          <span class="custom-control-label">&nbsp;</span>
                        </label>
                      </td>
                      <td>
                        <router-link
                          v-if="can(uiPermissions.RECIPES_VIEW)"
                          :to="`/recipes/${item.recipe_id}`"
                          target="_blank">
                          {{item.recipe_name}}
                        </router-link>
                        <template v-else>
                          {{item.recipe_name}}
                        </template>
                      </td>
                      <td>
                        <div v-if="item.is_card_available">Yes</div>
                        <template v-else>
                          <span id="not-available">
                            <i class="fe fe-warning fe-alert-triangle text-warning cursor pointer" aria-hidden="true"/>
                            No
                          </span>
                          <b-tooltip target="not-available" triggers="hover">
                            <div>Please, generate PDF card</div>
                          </b-tooltip>
                        </template>
                      </td>
                      <td>{{requestedBy(item.requested_by)}}</td>
                      <td>{{item.qty_2_people}}</td>
                      <td>{{item.qty_3_people}}</td>
                      <td>{{item.qty_4_people}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </dimmer>
          </div>
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Article Summary</h3>
              <div class="card-options">
                <button
                  v-if="can(uiPermissions.INTERNAL_PURCHASE_ARTICLES_EXPORT)"
                  :disabled="!selectedArticles.length"
                  class="btn btn-primary mr-4"
                  @click.prevent="exportArticles">
                  Export
                </button>
              </div>
            </div>
            <dimmer :active="loadingArticles">
              <div style="max-height: 30rem; overflow-y: auto;">
                <table class="table table-vcenter card-table sticky-table dataTables_wrapper">
                  <thead>
                    <tr class="thead-light">
                      <th v-if="can([uiPermissions.INTERNAL_PURCHASE_PURCHASE_ORDER_CREATE, uiPermissions.INTERNAL_PURCHASE_ARTICLES_EXPORT])" class="text-center">
                        <label class="custom-control custom-checkbox d-flex align-items-center justify-content-center">
                          <input
                            v-model="selectedAllArticles"
                            type="checkbox"
                            class="custom-control-input">
                          <span class="custom-control-label">&nbsp;</span>
                        </label>
                      </th>
                      <th
                        :class="column === 'article' ? `sorting_${sort}` : 'sorting'"
                        class="pointer"
                        @click="() => sortColumn('article', 'text', 'articles', 'name')">
                        Article
                      </th>
                      <th
                        :class="column === 'category' ? `sorting_${sort}` : 'sorting'"
                        class="pointer"
                        @click="() => sortColumn('category', 'text', 'articles', 'name')">
                        Category
                      </th>
                      <th
                        :class="column === 'supplier' ? `sorting_${sort}` : 'sorting'"
                        class="pointer"
                        @click="() => sortColumn('supplier', 'text', 'articles', 'name')">
                        Supplier
                      </th>
                      <th>Pack size</th>
                      <th
                        :class="column === 'oum' ? `sorting_${sort}` : 'sorting'"
                        class="pointer"
                        @click="() => sortColumn('oum', 'text', 'articles', 'name')">
                        OuM
                      </th>
                      <th
                        :class="column === 'min_order_qty' ? `sorting_${sort}` : 'sorting'"
                        class="pointer"
                        @click="() => sortColumn('min_order_qty', 'number', 'articles')">
                        MOQ
                      </th>
                      <th
                        :class="column === 'stock_on_hand' ? `sorting_${sort}` : 'sorting'"
                        class="pointer"
                        @click="() => sortColumn('stock_on_hand', 'number', 'articles')">
                        SOH
                      </th>
                      <th
                        :class="column === 'required_qty' ? `sorting_${sort}` : 'sorting'"
                        class="pointer"
                        @click="() => sortColumn('required_qty', 'text', 'articles')">
                        Required Qty. (Pieces)
                      </th>
                      <th
                        :class="column === 'decided_qty' ? `sorting_${sort}` : 'sorting'"
                        class="pointer"
                        @click="() => sortColumn('decided_qty', 'number', 'articles')">
                        Order Qty. in OuM
                      </th>
                      <th
                        :class="column === 'delivery_date' ? `sorting_${sort}` : 'sorting'"
                        class="pointer"
                        @click="() => sortColumn('delivery_date', 'text', 'articles')">
                        Delivery Date
                      </th>
                      <th
                        :class="column === 'status' ? `sorting_${sort}` : 'sorting'"
                        class="pointer"
                        @click="() => sortColumn('status', 'text', 'articles')">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in articles"
                      :key="item.id"
                      :class="{ 'ordered': item.status === 'ordered' }">
                      <td v-if="can([uiPermissions.INTERNAL_PURCHASE_PURCHASE_ORDER_CREATE, uiPermissions.INTERNAL_PURCHASE_ARTICLES_EXPORT])" class="text-center">
                        <label class="custom-control custom-checkbox mb-0">
                          <input
                            v-model="selectedArticles"
                            type="checkbox"
                            class="custom-control-input"
                            :value="item">
                          <span class="custom-control-label">&nbsp;</span>
                        </label>
                      </td>
                      <td>
                        <router-link
                          v-if="can(uiPermissions.ARTICLES_VIEW)"
                          :to="`/articles/${item.article.id}`"
                          target="_blank">
                          {{item.article.name}}
                        </router-link>
                        <template v-else>
                          {{item.article.name}}
                        </template>
                      </td>
                      <td>{{item.category.name}}</td>
                      <td>{{item.supplier.name}}</td>
                      <td>{{item.pack_size}}</td>
                      <td>{{item.oum.name}}</td>
                      <td>{{item.min_order_qty}}</td>
                      <td>{{item.stock_on_hand}}</td>
                      <td>{{item.required_qty}}</td>
                      <td>
                        <template v-if="item.status === 'ordered'">{{item.decided_qty}}</template>
                        <input
                          v-else
                          v-model="item.decided_qty"
                          :disabled="!can(uiPermissions.INTERNAL_PURCHASE_PURCHASE_ORDER_CREATE)"
                          required
                          type="text"
                          class="form-control"/>
                      </td>
                      <td>
                        <template v-if="item.status === 'ordered'">{{item.formatted_delivery_date}}</template>
                        <input
                          v-else
                          v-model="item.delivery_date"
                          :disabled="!can(uiPermissions.INTERNAL_PURCHASE_PURCHASE_ORDER_CREATE)"
                          required
                          type="date"
                          class="form-control">
                      </td>
                      <td>
                        <span v-if="item.status === 'requested'">Requested</span>
                        <span v-else-if="item.status === 'in-progress'">In progress</span>
                        <span v-else>Ordered</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </dimmer>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <edit-footer
            v-if="can(uiPermissions.INTERNAL_PURCHASE_PURCHASE_ORDER_CREATE)"
            :dirty="dirty"
            :isNew="isNew"
            :hide-delete="true"
            :disabled="isSubmitBtnDisabled"
            :submitting="submitting"
            :only-button="true"
            submit-title="Submit purchase orders">
            <div v-if="isOrderedArticlesSelected" class="mb-2">Please, uncheck ordered articles</div>
          </edit-footer>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import EditFooter from '@/components/EditFooter';
import EditHeader from '@/components/EditHeader';
import edit from '@/mixins/edit';
import download from '@/mixins/download';
import {internalPurchaseList, internalPurchase, internalPurchaseArticles, internalRecipesExport, internalRecipesPrint, internalArticlesExport, internalRecipesIngredientsPortioningExport, internalArticlesPurchaseOrder} from '@/services';
import sort from '@/mixins/sort';

export default {
  components: {
    EditFooter,
    EditHeader,
  },
  mixins: [
    edit,
    download,
    sort,
  ],
  data() {
    return {
      item: {
        notes: '',
      },
      recipes: [],
      articles: [],
      selectedRecipes: [],
      selectedArticles: [],
      loadingRecipes: false,
      loadingArticles: false,
    };
  },
  computed: {
    selectedAllRecipes: {
      get() {
        return this.recipes.length ? this.selectedRecipes.length === this.recipes.length : false;
      },
      set(value) {
        const selected = [];
        if (value) {
          this.recipes.forEach(recipe => {
            selected.push(recipe);
          });
        }

        this.selectedRecipes = selected;
      },
    },
    selectedAllArticles: {
      get() {
        return this.articles.length ? this.selectedArticles.length === this.articles.length : false;
      },
      set(value) {
        const selected = [];
        if (value) {
          this.articles.forEach(article => {
            selected.push(article);
          });
        }

        this.selectedArticles = selected;
      },
    },
    route() {
      this.clearChecked();
      return `/inventory/shopping/${this.$route.params.id}`;
    },
    isDownloadLinkDisabled() {
      return this.selectedRecipes.some(it => !it.is_card_available) || !this.selectedRecipes.length;
    },
    queryParams() {
      return this.selectedRecipes.filter(it => it.is_card_available).reduce((acc, recipe) => {
        acc += acc === '' ? `internal_purchase_recipes_ids[]=${recipe.id}` : `&internal_purchase_recipes_ids[]=${recipe.id}`;
        return acc;
      }, '');
    },
    isSubmitBtnDisabled() {
      return this.isOrderedArticlesSelected || !this.selectedArticles.length;
    },
    isOrderedArticlesSelected() {
      return this.selectedArticles.some(it => it.status === 'ordered');
    },
  },
  methods: {
    clearChecked() {
      this.selectedArticles = [];
      this.selectedRecipes = [];
      this.selectedAllRecipes = false;
      this.selectedAllArticles = false;
    },
    requestedBy(item) {
      return `${item.firstName} ${item.lastName}`;
    },
    async fetchData(id) {
      this.loadingRecipes = true;
      this.loadingArticles = true;

      const {item} = await internalPurchaseList.getById(id);
      Object.assign(this.item, item);

      try {
        const {item} = await internalPurchase.getByIdInEndpoint(id);
        this.recipes = item;
      }
      catch (e) {
        console.error(e);
      }
      finally {
        this.loadingRecipes = false;
      }

      try {
        const articles = await internalPurchaseArticles.getByIdInEndpoint(id);
        this.articles = articles.item.map(it => ({
          ...it,
          decided_qty: it.suggested_order_qty,
        }));
      }
      catch (e) {
        console.error(e);
      }
      finally {
        this.loadingArticles = false;
      }
    },
    async exportRecipes() {
      const internal_purchase_recipes_ids = this.selectedRecipes.map(it => it.id);
      this.download(await internalRecipesExport.exportPostByParameters({internal_purchase_recipes_ids}));
    },
    async exportRecipesIngredientsPortioning() {
      const internal_purchase_recipes_ids = this.selectedRecipes.map(it => it.id);
      this.download(await internalRecipesIngredientsPortioningExport.exportPostByParameters({internal_purchase_recipes_ids}));
    },
    async downloadPDF() {
      if (this.isDownloadLinkDisabled) { return; }
      const internal_purchase_recipes_ids = this.selectedRecipes.map(it => it.id);
      const {data, headers} = await internalRecipesPrint.download({internal_purchase_recipes_ids});
      headers['content-disposition'] += ';filename=recipe-cards.zip';
      this.download({data, headers});
    },
    async exportArticles() {
      const internal_purchase_articles_ids = this.selectedArticles.map(it => it.id);
      this.download(await internalArticlesExport.exportPostByParameters({internal_purchase_articles_ids}));
    },
    submitData() {
      const params = this.selectedArticles.filter(it => it.status !== 'ordered').reduce((acc, article) => {
        const articleBaseInfo = {
          internal_purchase_id: article.internal_purchase_id,
          article_id: article.article.id,
          qb_name: article.article.qb_name,
          suggested_qty: article.suggested_order_qty,
          decided_qty: article.decided_qty,
          oum_id: 3,
          category_id: article.category.id,
        };
        const obj = acc.find(it => it.supplier_id === article.supplier.id && it.delivery_date === article.delivery_date);
        if (obj) {
          obj.articles.push(articleBaseInfo);
        }
        else {
          acc.push({
            supplier_id: article.supplier.id,
            lead_time: article.lead_time,
            delivery_date: article.delivery_date,
            articles: [articleBaseInfo],
          });
        }
        return acc;
      }, []);
      return internalArticlesPurchaseOrder.saveOrUpdate({purchase_orders: params});
    },
    sortColumn(column, type = 'number', dataKey = 'articles', secondKey) {
      this.sort = (this.sort === 'desc') ? 'asc' : 'desc';
      this.column = column;

      if (secondKey) {
        if (this.sort === 'desc') {
          this[dataKey].sort((first, second) => type === 'number' ? parseFloat(second[this.column][secondKey]) - parseFloat(first[this.column][secondKey]) : (second[this.column][secondKey] < first[this.column][secondKey]) ? -1 : 1);
        }
        else { this[dataKey].sort((first, second) => type === 'number' ? parseFloat(first[this.column][secondKey]) - parseFloat(second[this.column][secondKey]) : (first[this.column][secondKey] < second[this.column][secondKey]) ? -1 : 1); }
      }
      else {
        if (this.sort === 'desc') {
          this[dataKey].sort((first, second) => type === 'number' ? parseFloat(second[this.column] || 0) - parseFloat(first[this.column] || 0) : (second[this.column] < first[this.column]) ? -1 : 1);
        }
        else { this[dataKey].sort((first, second) => type === 'number' ? parseFloat(first[this.column] || 0) - parseFloat(second[this.column] || 0) : (first[this.column] < second[this.column]) ? -1 : 1); }
      }
    },
  },
};
</script>

<style scoped>
.is-disabled {
  pointer-events: none;
  opacity: 0.65;
}

.ordered {
  background-color: #dff1cc;
}

.pointer {
  cursor: pointer;
}

.pointer:before {
  display: none;
}
</style>
